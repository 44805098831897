import React, { useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import BackyardOrderShop from './BackyardOrderShop'
import BackyardOrderList from './BackyardOrderList';
import BackyardOrderProduct from './BackyardOrderProduct';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import moment from 'moment';
import BackyardOrderPaymentList from './BackyardOrderPaymentList';

const BackyardOrderFinTab = (props) => {
  const { user, condition, conditionActions } = props;
  registerLocale('ja', ja);

  // const [index, setIndex] = useState(1);
  const handleTabSelect = (idx, lastIndex, e) => {
    conditionActions.setIndex(idx);
    if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を設定
      conditionActions.setShop(user.shopId);
    }
  };

  // const [shopMaster, setShopMaster] = useState([]);
  // const [planMaster, setPlanMaster] = useState([]);
  // const [productMaster, setProductMaster] = useState([]);
  // const [shop, setShop] = useState('');
  // const [plan, setPlan] = useState('0');
  // const [receiveDate, setReceiveDate] = useState(null);
  // const [selectReceiveDate, setSelectReceiveDate] = useState(null);
  // const [selectStatus, setSelectStatus] = useState([{ value: constClass.STATUS.UPAY, label: constClass.STATUS_NAME.UPAY },
  // { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
  // { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
  // { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
  // { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN }]);
  // const [status, setStatus] = useState([constClass.STATUS.UPAY, constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.FIN, constClass.STATUS.DFIN]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [isReload, setisReload] = useState(true);

  const handleChange = (event) => {
    if (event.target.name === 'shop') {
      conditionActions.setShop(event.target.value);
    } else if (event.target.name === 'plan') {
      conditionActions.setPlan(event.target.value);
      // } else if (event.target.name === 'startdate') {
      //   setStartDate(event.target.value);
      //   if (endDate === null) setEndDate(event.target.value);
      // } else if (event.target.name === 'enddate') {
      //   setEndDate(event.target.value);
      //   if (startDate === null) setStartDate(event.target.value);
    }
  }

  const handleSelectReceiveDate = (data) => {
    if (data.length <= 0) {
      conditionActions.setSelectReceiveDate(null);
    } else {
      conditionActions.setSelectReceiveDate(data);
    }
  }
  const handleSelectStatus = (data) => {
    if (data.length <= 0) {
      conditionActions.setSelectStatus(null);
    } else {
      conditionActions.setSelectStatus(data);
    }
  }
  const handleIsReload = (data) => {
    conditionActions.setIsReload(data);
  }

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (!condition.initFlag) {
      async function fetchData() {
        const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setShopMaster(shop);
        const plan = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setPlanMaster(plan);
        if (plan.length > 0) {//企画が複数あったら、開催中企画を表示。なければ最新企画を表示
          const nowdate = moment().format("YYYYMMDD");
          const nowplan = plan.find((item) => item.plan_start <= nowdate && item.plan_end >= nowdate);
          const defaultplan = nowplan ? nowplan.plan_id : plan.slice(-1)[0].plan_id;
          conditionActions.setPlan(defaultplan);
        }
        const product = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setProductMaster(product);
        if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を初期値に設定
          conditionActions.setShop(user.shopId);
        }
        conditionActions.setInitFlag();
      }
      fetchData();
    }
    return () => {
      conditionActions.setIsReload(true);
    }
  }, [user.shopId, user.userClass, conditionActions, condition.initFlag]);

  return (
    <div className="container">
      <div className="row mx-0 my-3 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>店舗</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="店舗" name="shop" value={condition.shop} onChange={handleChange} disabled={(condition.index === 1 && user.userClass === constClass.CLASS.SHOP) ? true : false}>
                    <option value="">- 全て -</option>
                    {condition.shopMaster.map((item) =>
                      <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>企画</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="企画" name="plan" value={condition.plan} onChange={handleChange}>
                    <option value="">- 全て -</option>
                    {condition.planMaster.map((item) =>
                      <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>受取日</span>
                </div>
                <div className="col text-center">
                  {/*<select className="custom-select w-100" aria-label="受取日" name="startdate" value={startDate} onChange={handleChange}>
                    <option value="">- 全て -</option>
                      {productMaster.filter(p => (p.plan_id = plan || !plan)) //productMasterをcount有りでフィルタ
                      .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                      .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map(item => // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                        <option value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                      )}
                      </select>*/}
                  <Select
                    styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                    options={condition.productMaster.filter(p => (p.plan_id === Number(condition.plan) || !condition.plan)) //productMasterをcount有りでフィルタ
                      .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                      .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map( // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                        item => ({ value: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2), label: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2) })
                      )}
                    isClearable={false}
                    isMulti
                    closeMenuOnSelect={false}
                    value={condition.selectReceiveDate}
                    onChange={handleSelectReceiveDate} />
                  {/*{<DatePicker className="form-control" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="2" selected={startDate} onChange={handleStartDateChange}/>}*/}
                </div>
              </div>
            </div>
            {condition.index === 0 &&
              <div className="col-2"></div>
            }
            {(condition.index !== 0) &&
              <div className="col-2">
                <div className="row">
                  {/* <div className="col-auto text-center align-self-center">
                <span>ステータス</span>
              </div> */}
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={[
                        { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
                        { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
                        { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
                        { value: constClass.STATUS.DFIN, label: constClass.STATUS_NAME.DFIN },
                        { value: constClass.STATUS.CCL, label: constClass.STATUS_NAME.CCL },
                        { value: constClass.STATUS.UPAY, label: constClass.STATUS_NAME.UPAY },
                        { value: constClass.STATUS.CUPAY, label: constClass.STATUS_NAME.CUPAY },
                      ]}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={condition.selectStatus}
                      placeholder={'ステータス...'}
                      backspaceRemovesValue={false}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      onChange={handleSelectStatus} />
                  </div>
                </div>
              </div>
            }
            <div className="col-1 text-center">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                onClick={() => handleIsReload(true)}>
                検索</button>
            </div>
            {/*<div className="col-1 text-center">
              <span>～</span>
            </div>
            <div className="col-2 text-center">
              {<DatePicker className="form-control" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="2" selected={endDate} onChange={handleEndDateChange}/>}
            </div>*/}
          </div>
        </div>
      </div>

      <Tabs onSelect={handleTabSelect} defaultIndex={condition.index}>
        <TabList className="nav nav-pills mb-2">
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 0 ? 'active' : ''}`} href="#">店舗別企画商品別</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 1 ? 'active' : ''}`} href="#">注文一覧(店舗受取)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 2 ? 'active' : ''}`} href="#">注文一覧(商品別)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 3 ? 'active' : ''}`} href="#">注文一覧(配送)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 4 ? 'active' : ''}`} href="#">決済データ一覧</div></Tab>
        </TabList>
        <TabPanel>
          <BackyardOrderShop {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderList {...props} delivery_flag={constClass.FLAG.OFF} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderProduct {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderList {...props} delivery_flag={constClass.FLAG.ON} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderPaymentList {...props} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BackyardOrderFinTab;