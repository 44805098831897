import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { CSVLink } from "react-csv";

const BackyardOrderShop = (props) => {
  const { user, condition, conditionActions } = props;
  const [shopData, setShopData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [ready, setReady] = useState(false);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      setReady(false);
      // console.log(props);
      var shopParams = { "operator": "and", "where": [] };
      var productParams = { "operator": "and", "where": [] };
      // 店舗
      if (condition.shop !== "") {
        shopParams.where.push({ "shop_id": condition.shop });
      }
      // 企画
      if (condition.plan !== "") {
        productParams.where.push({ "plan_id": condition.plan });
      }
      const searchParams = {
        shop: condition.shop,
        plan: condition.plan,
        receiveDate: condition.receiveDate,
      }
      const order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/shop_product/`, searchParams, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      //data.sort((a, b) => a.order_id - b.order_id);
      setOrderData(order);

      const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, shopParams, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      shop.sort((a, b) => a.shop_id - b.shop_id);
      setShopData(shop);

      const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, productParams, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      product.sort((a, b) => a.plan_id - b.plan_id ? a.plan_id - b.plan_id : a.product_id - b.product_id);
      setProductData(product);
      //getNewNumber();
      setReady(true);

      createCsvdata(shop, product, order);
      conditionActions.setIsReload(false);
    }
  }, [condition, conditionActions]);

  const createCsvdata = (shop, product, order) => {
    var data = [];
    var head = [];
    head.push({ label: "商品", key: "product" });
    head.push({ label: "合計", key: "product_count" });
    head.push({ label: "税抜合計金額", key: "product_total" });
    shop.forEach((s, idx) => {
      head.push({ label: s.shop_name, key: "shop" + idx });
    })
    product.forEach(p => {
      var count = { product: p.product_name.replace(/"/g, '""') };
      count = { ...count, product_count: order.filter(o => o.product_id === p.product_id).reduce((sum, o) => sum + Number(o.total_count), 0) };
      count = { ...count, product_total: order.filter(o => o.product_id === p.product_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(p.price_before_tax)), 0) };
      shop.forEach((shop, idx) => {
        const c = order.find(o => o.receive_shop === shop.shop_id && o.product_id === p.product_id) ? order.find(o => o.receive_shop === shop.shop_id && o.product_id === p.product_id).total_count : 0
        count = ({ ...count, ["shop" + idx]: c });
      });
      data.push(count);
    });
    var count = { product: '合計' };
    count = { ...count, product_count: order.reduce((sum, o) => sum + Number(o.total_count), 0) };
    count = { ...count, product_total: order.reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(product.find(p => p.product_id === o.product_id).price_before_tax)), 0) };
    shop.forEach((shop, idx) => {
      const c = order.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + Number(o.total_count), 0)
      count = ({ ...count, ["shop" + idx]: c });
    });
    data.push(count);
    count = { product: '税抜合計金額' };
    shop.forEach((shop, idx) => {
      const c = order.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(product.find(p => p.product_id === o.product_id).price_before_tax)), 0).toLocaleString()
      count = ({ ...count, ["shop" + idx]: c });
    });
    data.push(count);
    setCsvData({ header: head, list: data });
  }

  // const handleStatusButtonClick = async (order_id, status) => {
  //   if (orderData.find(o => o.order_id === order_id).status === status) {
  //     return;
  //   }
  //   const params = {
  //     status,
  //     user_id: user.userId,
  //   };
  //   try {
  //     setLockData(true);
  //     await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params);
  //   } catch (err) {
  //     if (err.response.data !== null) {
  //       addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast(err.response, { appearance: 'error', autoDismiss: true });
  //     }
  //   } finally {
  //     await refreshOrder();
  //     setLockData(false);
  //   }
  // }

  // const renderButton = (data, status, statusName, additionalStatus = []) => {
  //   additionalStatus.push(status);
  //   statusName = [constClass.STATUS_NAME.CALL, constClass.STATUS_NAME.PRE].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;
  //   return (
  //     <button type="button"
  //       disabled={lockData}
  //       className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
  //       onClick={() => handleStatusButtonClick(data.order_id, status)}>
  //       {statusName}
  //     </button>
  //   )
  // }

  // const countOrder = (status) => {
  //   // return orderData.reduce((prev, item) => { return prev + (item[constClass.COLUMN.CLASS] === user.userClass && status.includes(item.status) ? 1 : 0) }, 0);
  //   return orderData.reduce((prev, item) => { return prev + (status.includes(item.status) ? 1 : 0) }, 0);
  // }

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  //  const getNewNumber = async()=>{
  //    const NewNumber = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/saiban/`)).data;
  //    setNewNumber(NewNumber.find(n => n.shop_id === constClass.SHOP_ID) ? NewNumber.find(n => n.shop_id === constClass.SHOP_ID).number + 1 : 1);
  //  } 


  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {(shopData === null || productData === null || orderData === null || !ready) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(shopData !== null && productData !== null && orderData !== null && ready) && (
        <React.Fragment>
          {csvData && <div className="row mb-3 p-0">
            <div className="col-12 p-0 text-lef talign-self-end">
              <CSVLink className="btn btn-primary mb-1 px-4" data={csvData.list} headers={csvData.header} filename={"店舗別企画商品別.csv"}>
                CSVダウンロード
              </CSVLink>

            </div>
          </div>
          }
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped sticky_table">
                <thead className={`table-${constClass.COLOR.BUS}`}>
                  <tr>
                    <th>
                    </th>
                    {(props.condition.shop === "") &&
                      <th className="total">
                        <span>合計</span>
                      </th>
                    }
                    <th className="total">
                      <span>税抜合計金額</span>
                    </th>
                    {shopData.map((shop, idx) => (
                      <th key={'shop' + shop.shop_id}>
                        <span>{shop.shop_name}</span>
                      </th>
                    ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {productData.map((product, idx) => (
                    <tr key={'product' + product.product_id}>
                      {/* 商品名 */}
                      <th>
                        {product.product_name}
                      </th>
                      {(props.condition.shop === "") &&
                        <td className="text-center total">
                          {orderData.filter(o => o.product_id === product.product_id) ? orderData.filter(o => o.product_id === product.product_id).reduce((sum, element) => sum + Number(element.total_count), 0) : 0}
                        </td>
                      }
                      <td className="text-center total">
                        {orderData.filter(o => o.product_id === product.product_id) ? orderData.filter(o => o.product_id === product.product_id).reduce((sum, element) => sum + Number(element.total_count) * Math.floor(product.price_before_tax), 0).toLocaleString() : 0}
                      </td>
                      {shopData.map((shop, idx) => (
                        <td key={'order' + product.product_id + '_' + shop.shop_id} className="text-center">
                          {orderData.find(o => o.receive_shop === shop.shop_id && o.product_id === product.product_id) ? orderData.find(o => o.receive_shop === shop.shop_id && o.product_id === product.product_id).total_count : 0}
                        </td>
                      ))
                      }
                    </tr>
                  ))
                  }
                  <tr key={'product_count'}>
                    <th className="text-right">
                      合計
                    </th>
                    {(props.condition.shop === "") &&
                      <td className="text-center total">
                        {orderData.reduce((sum, element) => sum + Number(element.total_count), 0)}
                      </td>
                    }
                    <td className="text-center total">
                      {orderData.reduce((sum, element) => sum + Number(element.total_count) * Math.floor(productData.find(p => p.product_id === element.product_id).price_before_tax), 0).toLocaleString()}
                    </td>
                    {shopData.map((shop, idx) => (
                      <td key={'order_total_' + shop.shop_id} className="text-center">
                        {orderData.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + Number(o.total_count), 0)}
                      </td>
                    ))
                    }
                  </tr>
                  <tr key={'product_total'}>
                    <th className="text-right">
                      税抜合計金額
                    </th>
                    {(props.condition.shop === "") &&
                      <td className="text-center total">

                      </td>
                    }
                    <td className="text-center total">

                    </td>
                    {shopData.map((shop, idx) => (
                      <td key={'order_total_' + shop.shop_id} className="text-center">
                        {orderData.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(productData.find(p => p.product_id === o.product_id).price_before_tax)), 0).toLocaleString()}
                      </td>
                    ))
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default BackyardOrderShop;