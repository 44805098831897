import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import ReactToPrint from 'react-to-print';
import chroma from 'chroma-js';
import { useToasts } from 'react-toast-notifications';

const BackyardOrderProduct = (props) => {
  const { user, condition, conditionActions } = props;
  const [orderData, setOrderData] = useState(null);
  // const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const componentRef = useRef();
  const { addToast } = useToasts();

  const sortData = useCallback((a, b) => {
    if (condition.sortOrder.length <= 0) { // 初期値は企画・商品デプト・商品・受取店舗・受取日・受取時間帯
      return ((a.plan_id !== b.plan_id) ? Number(a.plan_id) - Number(b.plan_id) :
        (a.product_dept_code !== b.product_dept_code) ? Number(a.product_dept_code) - Number(b.product_dept_code) :
          (a.product_id !== b.product_id) ? Number(a.product_id) - Number(b.product_id) :
            (a.receive_shop !== b.receive_shop) ? Number(a.receive_shop) - Number(b.receive_shop) :
              (a.receive_date !== b.receive_date) ? new Date(a.receive_date) - new Date(b.receive_date) :
                Number(a.receive_time) - Number(b.receive_time))
    }
    for (var i = 0; i < condition.sortOrder.length; i++) {
      var item = condition.sortOrder[i];
      if (item.column === 'receive_date') {
        if (item.order === 'ASC') {
          if (new Date(a[item.column]) < new Date(b[item.column])) return -1;
          if (new Date(a[item.column]) > new Date(b[item.column])) return 1;
        } else {
          if (new Date(b[item.column]) < new Date(a[item.column])) return -1;
          if (new Date(b[item.column]) > new Date(a[item.column])) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [condition.sortOrder]);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      var reg_params = {};
      reg_params = {
        "operator": "and",
        "where": []
      }

      // 店舗
      if (user.userClass !== constClass.CLASS.ADMIN) {
        //店舗ユーザは自分の店舗のみ
        reg_params.where.push({ "receive_shop": user.shopId });
      } else if (condition.shop !== "") {
        reg_params.where.push({ "receive_shop": condition.shop });
      }
      // 企画
      if (condition.plan !== "") {
        reg_params.where.push({ "plan_id": condition.plan });
      }
      // 受取日
      if (condition.receiveDate !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "receive_date",
          "val": condition.receiveDate
        });
      }
      // ステータス
      if (condition.status !== null) {
        reg_params.where.push({
          "operator": "in",
          "attr": "status",
          "val": condition.status
        });
      }
      // // 受取日(開始)
      // if (props.condition.startDate) {
      //   reg_params.where.push({
      //     "operator": "gte",
      //     "attr": "receive_date",
      //     "val": props.condition.startDate
      //   });

      //   // 受取日(終了)
      //   if (props.condition.endDate) {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.endDate
      //     });
      //   } else {
      //     reg_params.where.push({
      //       "operator": "lte",
      //       "attr": "receive_date",
      //       "val": props.condition.startDate
      //     });
      //   }
      // }

      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      var list = [];

      data.forEach(p => {
        var order = {
          receipt_num: p.receipt_num,
          employee_flag: p.t_customer ? (p.t_customer.employee_flag === '1' ? '○' : '') : '',
          customer_name: p.customer_name,
          receive_shop: p.receive_shop,
          receive_shop_name: p.receive_shop_name,
          plan_id: p.plan_id,
          plan_name: p.plan_name,
          receive_date: p.receive_date,
          receive_time: p.receive_time,
          count: 0
        };
        p.t_order_detail.forEach(orderdetail => {
          const idx = list.findIndex(o =>
            o.product_id === orderdetail.product_id
            && o.receive_date === order.receive_date
            && o.receive_time === order.receive_time
            && o.receive_shop === order.receive_shop
          );
          order = { ...order, count: orderdetail.count }
          if (idx < 0) {
            var product = {
              idx: list.length + 1
              , plan_id: order.plan_id
              , plan_name: order.plan_name
              , receive_date: order.receive_date
              , receive_time: order.receive_time
              , receive_shop: order.receive_shop
              , product_id: orderdetail.product_id
              , receive_shop_name: order.receive_shop_name
              , product_code: orderdetail.product_code
              , product_name: orderdetail.product_name
              , product_category_id: orderdetail.m_product.m_product_category.product_c_id
              , product_category_name: orderdetail.m_product.m_product_category.product_c_name
              , product_dept_code: orderdetail.m_product.m_product_dept.product_d_code
              , product_dept_name: orderdetail.m_product.m_product_dept.product_d_name
              , product_dept_color: orderdetail.m_product.m_product_dept.product_d_color
              , early_discount: orderdetail.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外"
              , price_before_tax: orderdetail.price_before_tax
              , price: orderdetail.price
              , tax_rate: orderdetail.tax_rate
              , sumcount: orderdetail.count
              , order: [order]
            }
            list.push(product);
          } else {
            list[idx].sumcount = list[idx].sumcount + orderdetail.count;
            list[idx].order.push(order);
          }

        });
      });

      // list.sort((a, b) =>
      //   (a.plan_id !== b.plan_id) ? Number(a.plan_id) - Number(b.plan_id) :
      //     (a.product_dept_code !== b.product_dept_code) ? Number(a.product_dept_code) - Number(b.product_dept_code) :
      //       (a.product_id !== b.product_id) ? Number(a.product_id) - Number(b.product_id) :
      //         (a.receive_shop !== b.receive_shop) ? Number(a.receive_shop) - Number(b.receive_shop) :
      //           (a.receive_date !== b.receive_date) ? new Date(a.receive_date) - new Date(b.receive_date) :
      //             Number(a.receive_time) - Number(b.receive_time));
      // list.sort((a, b) => sortData(a, b));
      setOrderData(list);
      conditionActions.setIsReload(false);
    }
  }, [user, condition.shop, condition.plan, condition.receiveDate, condition.status, condition.isReload, conditionActions]);

  const handleHeaderClick = (column) => {
    var _sortOrder = JSON.parse(JSON.stringify(condition.sortOrder));
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    conditionActions.setSortOrder(_sortOrder);
    // var data = orderData.slice();
    // data.sort((a, b) => sortData(a, b));
    // setOrderData(data);
  }

  const handlePrintClick = async () => {
    const jwt = localStorage.getItem('jwt');
    try {
      const log = {
        command: 'order_product_print',
        receipt_num: orderData.map(p => p.order.map(o => o.plan_id + '-' + o.receipt_num))
      }
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/log/`, log, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    }
  }

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {condition.sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{condition.sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-9 pl-0 text-left align-self-end">
              {props.condition.shop && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].receive_shop_name}</h4></div></div>}
              <div className="row">
                <div className="col-2 pr-2">
                  {orderData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          注文情報印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                      onBeforePrint={handlePrintClick}
                    />}
                </div>

              </div>
            </div>
            <div className="col-3 m-0 p-0">
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-dept" height="1">
                <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('plan_id')}>
                      企画
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('product_dept_code')}>
                      商品<br />デプト
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('product_category_id')}>
                      商品<br />カテゴリ
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('product_code')}>
                      商品<br />コード
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" style={{ maxWidth: '100px' }} onClick={() => handleHeaderClick('product_code')}>
                      商品名
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('sumcount')}>
                      合計<br />注文数
                    </th>
                    {!props.condition.shop && (
                      <th className="text-center align-middle like-button" style={{ maxWidth: '150px' }} onClick={() => handleHeaderClick('receive_shop')}>
                        受取店舗
                      </th>
                    )}
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_date')}>
                      受取日
                    </th>
                    <th className="text-center text-nowrap align-middle like-button" onClick={() => handleHeaderClick('receive_time')}>
                      受取時間帯
                    </th>
                    <th className="text-center align-middle" style={{ minWidth: '150px' }}>
                      注文
                    </th>
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('plan_id')}
                    {renderSortTh('product_dept_code')}
                    {renderSortTh('product_category_id')}
                    {renderSortTh('product_code')}
                    {renderSortTh('product_code')}
                    {renderSortTh('sumcount')}
                    {!props.condition.shop && (renderSortTh('receive_shop'))}
                    {renderSortTh('receive_date')}
                    {renderSortTh('receive_time')}
                    <th className="text-center align-middle print-none">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                    <tr key={data.idx}>
                      {/* 企画 */}
                      <td className="text-center align-middle p-1">
                        {data.plan_name}
                      </td>
                      {/* 商品デプト */}
                      <td className="text-center align-middle p-1"
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty('color', data.product_dept_color);
                            el.style.setProperty('background-color', chroma(data.product_dept_color).alpha(0.3).css(), 'important');
                          }
                        }}>
                        {data.product_dept_name}
                      </td>
                      {/* 商品カテゴリ */}
                      <td className="text-center align-middle p-1">
                        {data.product_category_name}
                      </td>
                      {/* 商品コード */}
                      <td className="text-center align-middle p-1">
                        {data.product_code}
                      </td>
                      {/* 商品名 */}
                      <td className="text-center align-middle p-1" style={{ maxWidth: '150px' }}>
                        <div className="row p-0 m-1">
                          <div className="col-12 text-left align-middle  p-0 m-0">{data.product_name}</div>
                        </div>
                      </td>
                      {/* 注文数 */}
                      <td className="text-center align-middle p-1">
                        {data.sumcount}<br />
                      </td>
                      {/* 受取店舗 */}
                      {!props.condition.shop && (
                        <td className="text-center align-middle p-1" style={{ maxWidth: '150px' }}>
                          <div className="row p-0 m-1">
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{data.receive_shop}</div>
                            <div className="col text-left align-self-center  p-0 m-0">{data.receive_shop_name}</div>
                          </div>
                        </td>
                      )}
                      {/* 受取日 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.receive_date}
                      </td>
                      {/* 受取時間帯 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {constClass.RECEIVE_TIME_NAME[data.receive_time]}
                      </td>
                      {/* 注文 */}
                      <td className="text-center align-middle p-0" style={{ minWidth: '150px' }}>
                        {data.order.sort((a, b) => a.receipt_num - b.receipt_num).map((order, order_idx) => (
                          <div className="row p-0 m-1" key={order.receipt_num}>
                            <div className="col-auto text-left align-middle py-0 pl-0 pr-1 m-0">{order.receipt_num}</div>
                            <div className="col text-left align-middle  p-0 m-0">{order.customer_name}</div>
                            <div className="col-auto  text-right align-middle py-0 pl-1 pr-0 m-0">{order.count}</div>
                          </div>
                        ))
                        }
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrderProduct;