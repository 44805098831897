import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const User = (props) => {
  const { user } = props;
  const [areaId, setAreaId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [areaMaster, setAreaMaster] = useState([]);
  const [shopMaster, setShopMaster] = useState([]);
  const [userNew, setUserNew] = useState({ user_id: '', user_class: 0, shop_id: 0, user_name: '', user_short: '', password: '', mail_address: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [errCheckMsg, setErrCheckMsg] = useState({});
  const { addToast } = useToasts();

  const refreshUser = async () => {
    const jwt = localStorage.getItem('jwt');
    var reg_params = {};
    reg_params = {
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.shop_id !== b.shop_id ? a.shop_id - b.shop_id : a.user_id - b.user_id);
    setUserData(data);
    const new_data = { user_id: '', user_class: 0, shop_id: 0, user_name: '', user_short: '', password: '', mail_address: '', upd_date: null, updflg: false };
    setUserNew(new_data);

    const area = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/area/`)).data;
    setAreaMaster(area);

    const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setShopMaster(shop);
    // エラーメッセージ初期化
    setErrCheckMsg({});
  }

  const handleUserRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.user_name) {
      window.alert('ユーザ名を入力してください。');
      return;
    }
    if(data.upd_date===null && (!data.user_id || !data.password)){
      window.alert('新規登録時はユーザID・パスワードを入力してください。');
      return;
    }
    const params = {
      user_id: data.user_id,
      user_class: data.user_class,
      shop_id: data.shop_id,
      user_name: data.user_name,
      user_short: data.user_short,
      password: data.password ? data.password : null,
      mail_address: data.mail_address,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }

  const handleUserDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      user_id: data.user_id,
      user_name: data.user_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }
  const handleChangeArea = (e) => {
    const target = e.target;
    const value = target.value;
    setAreaId(value);
  }
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...userNew, [key]: event.target.value };
      setUserNew(new_data);
    } else {
      const _userData = [...userData]
      _userData[index] = { ..._userData[index], [key]: event.target.value };
      setUserData(_userData)
    }
    if (key === 'mail_address') {
      setErrCheckMsg({...errCheckMsg, [index]: mailAddressValidation(event.target.value)});
    }
  }
  
  const mailAddressValidation = (value) => {
    if (value === '') return '';
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!regex.test(value)) return '※正しいメールアドレスを入力してください';
    return '';
  }

  const renderRegistButton = (data, idx) => {
    return (
      <button type="button"
        disabled={lockData || !!errCheckMsg[idx]}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.user_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserDeleteClick(data)}>
        削除
      </button>
    )
  }

  useEffect(() => {
    refreshUser();
  }, [areaId]);

  return (
    <div className="container">
      <div className="row my-2 ">
        <div className="col-2 text-center line-height-2-2">
          <span className="d-inline-block align-middle">店舗エリア</span>
        </div>
        <div className="col-3 text-center line-height-2-2">
          <select className="custom-select w-100" aria-label="エリア" name="area_id" value={areaId || ''} onChange={handleChangeArea}>
            <option value="">- 全て -</option>
            {areaMaster.map((item) =>
              <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
            )}
          </select>
        </div>
      </div>

      <div className="row d-none"><div className="col-12">{util.inspect(userData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {userData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {userData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    ユーザID
                  </td>
                  <td className="text-center">
                    パスワード
                  </td>
                  <td className="text-center">
                    ユーザ区分
                  </td>
                  <td className="text-center">
                    店舗
                  </td>
                  <td className="text-center">
                    ユーザ名
                  </td>
                  <td className="text-center">
                    ユーザ名略称
                  </td>
                  <td className="text-center">
                    メールアドレス
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* ユーザID */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_id_new"} name="user_id[]" value={userNew.user_id} onChange={handleChangeCell('new', 'user_id')}></input>
                  </td>
                  {/* パスワード */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"password_new"} name="password[]" value={userNew.password} onChange={handleChangeCell('new', 'password')}></input>
                  </td>
                  {/* ユーザ区分 */}
                  <td className="text-center align-middle">
                    <select className="form-control" name="user_class[]" id={"user_class_new"} onChange={(e) => handleChangeCell('new', 'user_class')}>
                      <option value={constClass.CLASS.SHOP}>{constClass.CLASS_NAME.SHOP}</option>
                      <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
                    </select>
                  </td>
                  {/* 店舗 */}
                  <td className="text-center align-middle">
                    <select className="form-control" name="shop_id[]" id={"shop_id_new"} onChange={(e) => handleChangeCell('new', 'shop_id')}>
                      {shopMaster.filter(s => !areaId || s.area_id === Number(areaId)).map((data2, idx2) => (
                        <option key={data2.shop_id} value={data2.shop_id} >{data2.shop_name}</option>
                      ))}</select>
                  </td>
                  {/* ユーザ名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_name_new"} name="user_name[]" value={userNew.user_name} onChange={handleChangeCell('new', 'user_name')}></input>
                  </td>
                  {/* ユーザ名略称 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_short_new"} name="user_short[]" value={userNew.user_short} onChange={handleChangeCell('new', 'user_short')}></input>
                  </td>

                  {/* メールアドレス */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"mail_address_new"} name="mail_address[]" value={userNew.mail_address} onChange={handleChangeCell('new', 'mail_address')}></input>
                    {!!errCheckMsg['new'] && <p className="text-danger mb-0">{errCheckMsg['new']}</p>}
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(userNew, 'new')}
                  </td>
                </tr>
                {userData.filter(s => !areaId || shopMaster.filter(a => a.area_id === Number(areaId)).map(m => m.shop_id).includes(s.shop_id)).map((data, idx) => (
                  <tr key={idx}>
                    {/* ユーザID */}
                    <td className="text-center align-middle">
                      {data.user_id}
                    </td>
                    {/* パスワード */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"password" + idx} name="password[]" value={data.password} onChange={handleChangeCell(idx, 'password')}></input>
                    </td>
                    {/* ユーザ区分 */}
                    <td className="text-center align-middle">
                      <select className="form-control" name="user_class[]" id={"user_class" + idx} value={data.user_class} onChange={(e) => handleChangeCell(idx, 'user_class')}>
                        <option value={constClass.CLASS.SHOP}>{constClass.CLASS_NAME.SHOP}</option>
                        <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
                      </select>
                    </td>
                    {/* 店舗 */}
                    <td className="text-center align-middle">
                      <select className="form-control" name="shop_id[]" id={"shop_id" + idx} value={data.shop_id} onChange={handleChangeCell(idx, 'shop_id')}>
                        {shopMaster.filter(s => !areaId || s.area_id === Number(areaId)).map((data2, idx2) => (
                          <option key={data2.shop_id} value={data2.shop_id} >{data2.shop_name}</option>
                        ))}</select>
                    </td>
                    {/* ユーザ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_name" + idx} name="user_name[]" value={data.user_name} onChange={handleChangeCell(idx, 'user_name')}></input>
                    </td>
                    {/* ユーザ名略称 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_short" + idx} name="user_short[]" value={data.user_short} onChange={handleChangeCell(idx, 'user_short')}></input>
                    </td>
                    {/* メールアドレス */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"mail_address" + idx} name="mail_address[]" value={data.mail_address || ''} onChange={handleChangeCell(idx, 'mail_address')}></input>
                      {!!errCheckMsg[idx] && <p className="text-danger mb-0">{errCheckMsg[idx]}</p>}
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data, idx)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default User;