import constClass from '../../Constants/Constants';

const ProductList = ({products, disabled, message, receive_shop, displayCartCount, addCart, subCart}) => {

  const getDateString = (date = null) => {
    const sysdate = new Date();
    if (date === null) {
      date = sysdate;
    }
    return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2)) + (('00' + String(date.getDate())).slice(-2));
  }

  const checkEarlyDiscount = (product) => {
    return product.early_discount_start <= getDateString() && product.early_discount_end >= getDateString();
  }

  const getShortDateString = (date) => {
    date = date.replace(/-/g, '');
    return Number(date.substr(4, 2)).toString() + "/" + Number(date.substr(6, 2)).toString();
  }

  return (
    <div className="row mx-0">
      <div className="col px-0">
        {products.map((item, idx) => // 早割対象フィルタ
          <div key={item.product_id}>
            <div className="row mx-0 bg-white px-3-env pt-2 pb-1">
              <div className="col bg-white py-1 px-1 align-self-center">
                <div className="row mx-0 text-center">
                  {item.product_image &&
                    <div className="col px-0 bg-white">
                      <img className="w-75" src={`${constClass.PRODUCT_IMAGE_DIR}${item.product_image}`} alt={item.product_name} />
                    </div>
                  }
                </div>
                {checkEarlyDiscount(item) &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white">
                      <span className="badge badge-warning p-1 align-middle">早割対象商品</span>
                      <span className="font-weight-normal px-2 align-middle">{getShortDateString(item.early_discount_end)}までのご注文で早割適用</span>
                    </div>
                  </div>
                }
                <div className="row mx-0 text-left">
                  <div className="col-9 px-0 bg-white">{item.product_name}</div>
                  <div className="col-3 px-0 bg-white text-right">&yen;{Math.floor(item.price_before_tax).toLocaleString()}<br /><small>&yen;{item.price.toLocaleString()}(税込)</small></div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col px-0 bg-white font-weight-normal">{item.product_detail}</div>
                </div>
                {item.point > 0 &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white text-danger">ご購入時にお買い物ポイント{item.point}pプレゼント！</div>
                  </div>
                }
                <div className="row mx-4">
                  <div className="col-auto text-center p-1 ml-1">
                    <button
                      disabled={((item.year_round_type!=="1" || item.stock>0) && (item.stock - item.order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                      className={`btn btn-active w-100 h-100 py-0 px-2`}
                      onClick={() => { subCart(item.product_id) }}>
                      －
                    </button>
                  </div>
                  <div className="col text-center py-1 px-1 align-self-center">
                    <div className="bg-white border-textbox">
                      <h4 className="my-1">
                        {displayCartCount(item.plan_id, item.product_id)}
                      </h4>
                    </div>
                  </div>
                  <div className="col-auto text-center p-1 mr-1">
                    <button
                      disabled={((item.year_round_type!=="1" || item.stock>0) && (item.stock - item.order_count) <= 0 ) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                      className={`btn btn-active w-100 h-100 py-0 px-2`}
                      onClick={() => { addCart(item.product_id) }}>
                      ＋
                    </button>
                  </div>
                </div>
                <div className="row mx-0 text-center">
                  <div className="col px-0 bg-white font-weight-normal text-danger">{message[item.product_id]}</div>
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
              <div className="col">
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;