import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import { ToastProvider } from 'react-toast-notifications';
import BackyardOrderFinTab from '../Components/BackyardOrder/BackyardOrderFinTab';
import PlanCategoryMaster from '../Components/Master/PlanCategory';
import ProductCategoryMaster from '../Components/Master/ProductCategory';
import constClass from '../Constants/Constants';
import BackyardOrderInput from '../Components/BackyardOrder/BackyardOrderInput';
import PlanMaster from '../Components/Master/Plan'
import ProductMaster from '../Components/Master/Product'
import ProductDept from '../Components/Master/ProductDept'
import NoticeInterval from '../Components/Master/NoticeInterval';
import UserMaster from '../Components/Master/User'
import BackyardOrderCondition from '../Slices/BackyardOrderCondition';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const conditionActions = useActions(BackyardOrderCondition.actions);
  const condition = useSelector(state => state.BackyardOrderCondition);

  const _userProps = { user, userActions, conditionActions, condition, ...props };
  return (
    <div className='backyard-top'>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}`} >
                  <BackyardOrderFinTab sts={constClass.STATUS.REG} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}plancategory`} >
                  <PlanCategoryMaster {..._userProps} />
                </Route>
                <Route path={`${props.match.path}productcategory`} >
                  <ProductCategoryMaster {..._userProps} />
                </Route>
                <Route path={`${props.match.path}order/input/:id`}>
                  <BackyardOrderInput {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}order/input`} >
                  <BackyardOrderInput {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}plan`} >
                  <PlanMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}product`} >
                  <ProductMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}productdept`} >
                  <ProductDept {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}noticeinterval`} >
                  <NoticeInterval {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user`} >
                  <UserMaster {..._userProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;