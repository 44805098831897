import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import util from 'util';

const GuestOnly = (props) => {
  // static contextTypes = {
  //   router: PropTypes.object.isRequired
  // }
  const { user } = props;
  const history = useHistory();

  useEffect(() => {
    if (user.isLoggedIn) {
      if (props.location.pathname === props.children.props.path) {
        history.replace(`${props.match.path}`);
      }
    }
  }, [user, history, props.location.pathname, props.children.props.path, props.match.path])

  return <div><div className="d-none">{util.inspect(props.location)}</div>{props.children}</div>;
}

GuestOnly.propTypes = {
  children: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

// function select({ auth }) {
//   return { auth };
// }

export default GuestOnly;